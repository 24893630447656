<template>
  <div class="s-progress-bar">
    <div
      v-for="(progress, index) in progresses"
      :key="index"
      class="s-progress-bar__progress"
      :style="{
        background: `var(--${progress.color})`,
        width: `${(progress.value / max) * 100}%`,
      }"
    />
  </div>
</template>

<script setup lang="ts">
import useProgress from "@/lib/components/logic/atoms/useProgress";

const props = defineProps(useProgress.props);

const { progresses, max } = useProgress.use(props);
</script>

<style lang="postcss">
.s-progress-bar {
  @apply relative h-2.5 w-full rounded-full bg-gray-200;

  &__progress {
    @apply absolute h-2.5 rounded-full transition-all;
  }
}
</style>
